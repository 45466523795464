@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .outext-ellipsis {
    @apply whitespace-nowrap overflow-hidden text-ellipsis;
  }
}

/* Custom Overflow-Y Scroll Between 680px and 1064px */
@layer utilities {
  input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
  }
  @media (min-width: 680px) and (max-width: 1064px) {
    .overflow-y-range-scroll {
      overflow-y: scroll;
    }
  }
}

@layer base {
  :root {
    --foreground-clr: 10 13 20;
    --background-clr: 255 255 255;

    --primary-darker: 22 38 100;
    --primary-dark: 37 62 167;
    --primary-base: 55 93 251;
    --primary-light: 194 214 255;
    --primary-lighter: 235 241 255;

    --primary-darker: 22 38 100;
    --primary-dark: 37 62 167;
    --primary-base: 55 93 251;
    --primary-light: 194 214 255;
    --primary-lighter: 235 241 255;

    --bg-strong: 31 31 31;
    --bg-surface: 32 35 45;
    --bg-soft: 226 228 233;
    --bg-weak: 246 248 250;
    --bg-main: 23 23 23;

    --text-main: 10 13 20;
    --text-sub: 82 88 102;
    --text-soft: 134 140 152;
    --text-disabled: 123 123 123;
    --text-opposite: 255 255 255;
    --text-blue: 0 128 178;
    --text-toast: 247, 247, 249;

    --stroke-strong: 10 13 20;
    --stroke-sub: 22 25 34;
    --stroke-soft: 55 55 55;
    --stroke-disabled: 49 53 63;
    --stroke-opposite: 255 255 255;

    --icon-strong: 10 13 20;
    --icon-sub: 123 123 123;
    --icon-soft: 134 140 152;
    --icon-disabled: 123 123 123;
    --icon-opposite: 255 255 255;

    --outneutral-900: 10 13 20;
    --outneutral-800: 22 25 34;
    --outneutral-700: 32 35 45;
    --outneutral-600: 49 53 63;
    --outneutral-500: 82 88 102;
    --outneutral-400: 134 140 152;
    --outneutral-300: 205 208 213;
    --outneutral-200: 226 228 233;
    --outneutral-100: 246 248 250;
    --outneutral-0: 255 255 255;

    --outorange-darker: 110 51 12;
    --outorange-dark: 194 84 10;
    --outorange-base: 241 123 44;
    --outorange-light: 255 218 194;
    --outorange-lighter: 254 243 235;

    --outred-darker: 113 14 33;
    --outred-dark: 175 29 56;
    --outred-base: 255 70 70;
    --outred-light: 248 201 210;
    --outred-lighter: 253 237 240;
    --outred-toast: 255 70 70;

    --outgreen-darker: 23 100 72;
    --outgreen-dark: 45 159 118;
    --outgreen-base: 56 199 147;
    --outgreen-light: 203 245 229;
    --outgreen-lighter: 239 250 246;
    --outgreen-toast: 0 178 7;

    --outyellow-darker: 105 61 17;
    --outyellow-dark: 180 120 24;
    --outyellow-base: 242 174 64;
    --outyellow-light: 251 223 177;
    --outyellow-lighter: 254 247 236;
    --outyellow-compact: 251 188 5;

    --outpurple-darker: 43 22 100;
    --outpurple-dark: 90 54 191;
    --outpurple-base: 110 63 243;
    --outpurple-light: 202 194 255;
    --outpurple-lighter: 238 235 255;

    --outpink-darker: 98 15 108;
    --outpink-dark: 156 35 169;
    --outpink-base: 226 85 242;
    --outpink-light: 249 194 255;
    --outpink-lighter: 253 235 255;

    --outeal-darker: 22 69 100;
    --outeal-dark: 31 135 173;
    --outeal-base: 53 185 233;
    --outeal-light: 194 239 255;
    --outeal-lighter: 235 250 255;

    --success: 56 199 147;
    --warning: 241 123 44;
    --error: 255 70 70;
    --information: 55 93 251;
    --away: 242 174 64;
    --feature: 110 63 243;
    --neutral: 134 140 152;
    --verified: 53 185 233;

    --red-light: 248 201 210;
    --orange-light: 253 232 215;
    --green-light: 203 245 229;
    --blue-light: 194 214 255;
    --white-light: 226 228 233;
    --white-compact: 255 255 255;
    --black-compact: 23 23 23;

    --red-lighter: 253 237 240;
    --orange-lighter: 254 243 235;
    --green-lighter: 239 250 246;
    --blue-lighter: 235 241 255;
    --white-lighter: 32 35 45;

    --red-darker: 113 14 33;
    --green-darker: 23 100 72;
    --gray-dark: 31 31 31;
    --gray-lighter: 74 74 74;
    --border-gray: 55 55 55;
    --bg-disabled: 74 74 74;
    --white-artboard: 247 247 249;
    --gray-low: 164 163 166;
  }

  :root[data-mode="dark"] {
    --foreground-clr: 255 255 255;
    --background-clr: 10 13 20;

    --primary-darker: 235 241 255;
    --primary-dark: 194 214 255;
    --primary-base: 55 93 251;
    --primary-light: 37 62 167;
    --primary-lighter: 255 255 255;

    --bg-strong: 31 31 31;
    --bg-surface: 226 228 233;
    --bg-soft: 92 92 92;
    --bg-weak: 92 92 92;
    --bg-main: 23 23 23;

    --text-main: 255 255 255;
    --text-sub: 123 123 123;
    --text-soft: 74 74 74;
    --text-disabled: 123 123 123;
    --border-gray: 55 55 55;
    --text-opposite: 10 13 20;
    --text-blue: 0 128 178;
    --text-toast: 247, 247, 249;

    --stroke-strong: 255 255 255;
    --stroke-sub: 246 248 250;
    --stroke-soft: 55 55 55;
    --stroke-disabled: 205 208 213;
    --stroke-opposite: 10 13 20;

    --icon-strong: 255 255 255;
    --icon-sub: 123 123 123;
    --icon-soft: 134 140 152;
    --icon-disabled: 82 88 102;
    --icon-opposite: 10 13 20;

    --outneutral-900: 255 255 255;
    --outneutral-800: 246 248 250;
    --outneutral-700: 226 228 233;
    --outneutral-600: 205 208 213;
    --outneutral-500: 173 167 153;
    --outneutral-400: 134 140 152;
    --outneutral-300: 82 88 102;
    --outneutral-200: 49 53 63;
    --outneutral-100: 32 35 45;
    --outneutral-0: 10 13 20;

    --outorange-darker: 254 243 235;
    --outorange-dark: 255 218 194;
    --outorange-base: 241 123 44;
    --outorange-light: 194 84 10;
    --outorange-lighter: 110 51 12;

    --outred-darker: 253 237 240;
    --outred-dark: 248 201 210;
    --outred-base: 255 70 70;
    --outred-light: 175 29 56;
    --outred-lighter: 113 14 33;
    --outred-toast: 255 70 70;

    --outgreen-darker: 239 250 246;
    --outgreen-dark: 203 245 229;
    --outgreen-base: 56 199 147;
    --outgreen-light: 45 159 118;
    --outgreen-lighter: 23 100 72;
    --outgreen-toast: 0 178 7;

    --outyellow-darker: 254 247 236;
    --outyellow-dark: 251 223 177;
    --outyellow-base: 242 174 64;
    --outyellow-light: 180 120 24;
    --outyellow-lighter: 105 61 17;

    --outpurple-darker: 238 235 255;
    --outpurple-dark: 202 194 255;
    --outpurple-base: 110 63 243;
    --outpurple-light: 90 54 191;
    --outpurple-lighter: 43 22 100;

    --outpink-darker: 253 235 255;
    --outpink-dark: 249 194 255;
    --outpink-base: 226 85 242;
    --outpink-light: 156 35 169;
    --outpink-lighter: 98 15 108;

    --outeal-darker: 235 250 255;
    --outeal-dark: 194 239 255;
    --outeal-base: 53 185 233;
    --outeal-light: 31 135 173;
    --outeal-lighter: 22 69 100;

    --success: 56 199 147;
    --warning: 241 123 44;
    --error: 255 70 70;
    --information: 55 93 251;
    --away: 242 174 64;
    --feature: 110 63 243;
    --neutral: 134 140 152;
    --verified: 53 185 233;

    --red-light: 248 201 210;
    --orange-light: 253 232 215;
    --green-light: 203 245 229;
    --blue-light: 194 214 255;
    --white-light: 226 228 233;

    --red-lighter: 253 237 240;
    --orange-lighter: 254 243 235;
    --green-lighter: 239 250 246;
    --blue-lighter: 235 241 255;
    --white-lighter: 32 35 45;

    --red-darker: 113 14 33;
    --green-darker: 23 100 72;
    --white-artboard: 247 247 249;
    --gray-low: 164 163 166;
    --gray-lighter: 74 74 74;
  }
}

body {
  color: var(--foreground-clr);
  background: var(--background-clr);
}

@font-face {
  font-family: 'Pilat Wide';
  src: url('/fonts/PilatWide-Black.woff2') format('woff2');
  font-weight: 950;
  /* Extra Bold weight */
  font-style: normal;
}

.layout-body {
  background-position: calc(100% - 50%) -10rem;
  background-size: 45rem;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.content_holder {
  gap: 60px;
}

.login_contnet {
  gap: 30px;
}

.email_log_in_holder {
  gap: 15px;
}

.email {
  gap: 19px;
}

.email-1 {
  font-feature-settings:
    "clig" off,
    "liga" off;
}

.password {
  gap: 19px;
}

.frame_121541 {
  flex: 1 0 0;
}

.password-1 {
  font-feature-settings:
    "clig" off,
    "liga" off;
}

.log_in_button {
  gap: 19px;
}

.continue {
  font-feature-settings:
    "clig" off,
    "liga" off;
}

.don_t_have_an_account__sign_up {
  font-feature-settings:
    "clig" off,
    "liga" off;
}

.forgot_password_-1 {
  font-feature-settings:
    "clig" off,
    "liga" off;
}

.log_in {
  font-feature-settings:
    "clig" off,
    "liga" off;
  letter-spacing: -0.495px;
}

.divider {
  gap: 5px;
}

.or {
  font-feature-settings:
    "clig" off,
    "liga" off;
}

.oauth_buttons_interaction {
  flex: 1 0 0;
}

.frame_7 {
  flex: 1 0 0;
}

.frame_8 {
  flex: 1 0 0;
}

.vector {
  fill: #fff;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}

.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid black;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}

.loader::after {
  animation-delay: 1s;
}

.card-bg-blue {
  background-image: linear-gradient(180deg, #c2d6ff 0%, #ebf1ff 100%);
}

.card-bg-green {
  background-image: linear-gradient(180deg, #D4F7E9 0%, #EFFAF6 100%);
}

.card-bg-red {
  background-image: linear-gradient(180deg, #F9D2DA 0%, #FDEDF0 100%);
}

.dark .card-bg-blue,
.dark .card-bg-green,
.dark .card-bg-red {
  background-image: none;
  background: none;
  border: 1px solid rgb(var(--stroke-soft));
}

.border-gradient {
  background-image: linear-gradient(90.2deg, #093637 0.18%, #44A08D 99.83%);
}

.sparkChart svg {
  height: 42px;
}

.gradient-text {
  background: linear-gradient(89.89deg, #22C1C3 0.1%, #6EF195 99.9%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  line-height: 1.2;
  /* Adjust as needed */
  padding: 0.1em 0;
  /* Add some padding to prevent cutoff */
}

.gradient-border {
  border-width: 0 0 1px 0;
  /* Only bottom border */
  border-style: solid;
  border-image: linear-gradient(89.89deg, #22C1C3 0.1%, #6EF195 99.9%) 1;
  /* The gradient for the border */
}

.red-gradient-text {
  background: linear-gradient(90deg, #F40752 0%, #F65770 49%, #F9AB8F 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  line-height: 1.2;
  /* Adjust as needed */
  padding: 0.1em 0;
  /* Add some padding to prevent cutoff */
}

.AccordionChevron {
  transition: transform 300ms;
}

.AccordionTrigger[data-state="open"]>.AccordionChevron {
  transform: rotate(180deg);
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.social-hover:hover svg path,
.social-hover:hover {
  fill: black;
  background-color: white;
}

.send-broadcast-icon:hover svg path,
.send-broadcast-icon:hover {
  stroke: black;
  background-color: white;
}

.broadcast-confirm-delete:hover svg path,
.broadcast-confirm-delete:hover {
  fill: white;
  background-color: white;
  stroke: black;
}

.broadcasts-delete-hover:hover svg path,
.broadcasts-delete-hover:hover {
  fill: white;
  background-color: white;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bg-gradient-circle {
  background: conic-gradient(from -90deg,
      transparent 0%,
      transparent 50%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.5) 100%);
  animation: rotate-gradient 1s linear infinite;
}

@keyframes rotate-gradient {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate-gradient 1s linear infinite;
}

.custom-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
}

.custom-scrollbar::-webkit-scrollbar:hover {
  background-color: transparent;
  width: 0px;
}

.custom-scrollbar::-webkit-scrollbar-track:hover {
  background-color: rgb(var(--bg-main) / 1);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(var(--bg-strong) / 0.2);
  border-radius: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgb(var(--bg-strong) / 0.4);
}

.titan-one {
  font-family: "Titan One", sans-serif !important;
}

.virtuoso-item:last-child {
  margin-bottom: 16px;
  /* Adjust as necessary */
}

.cropper-dashed {
  border: none !important;
}

.swap-input {
  font-size: 20px !important;
}

.cropper-view-box {
  overflow: hidden;
  outline: 6px dashed black !important;
  /* 4px black dashed border */
  outline-offset: -2px !important;
  /* Optional: adjust offset to center the border */
}

.cropper-center {
  opacity: 0 !important;
}

.cropper-line {
  background-color: transparent !important;
}

.cropper-point {
  background-color: transparent !important;
}

.cropper-modal {
  background-color: #171717 !important;
}

.gradient-green-text {
  background: linear-gradient(89.89deg, #22C1C3 0.1%, #6EF195 99.9%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  line-height: 22px;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate360 1s linear infinite;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar,
.no-scrollba>div {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.ptr__pull-down--pull-more {
  display: none !important;
}

.ptr__children{
  transform: none !important; 
}
.ptr__pull-down{
  display: none !important;
}

.menu-shadow {
  box-shadow: 0px -20px 20px -10px rgba(0, 0, 0, 0.44);
}
.nav-shadow {
  box-shadow: 1px 29px 32px -19px rgba(0, 0, 0, 0.44);
}
